/**
 *  urlApi.js
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO 
 *  @description: Manejor de las ruta directas al API
*/

function url() {

    //let url = 'http://192.168.1.152:8081';
    //let url = 'https://igateapi.herokuapp.com';
    //let url = 'https://igateapitest.herokuapp.com';
    let url = 'https://igateapidev.herokuapp.com';

    if (process.env.NODE_ENV === 'production') {
        //url = 'https://igateapi.herokuapp.com';
        //url = 'https://igateapitest.herokuapp.com';
        url = 'https://igateapidev.herokuapp.com';
    };

    return url
};

export const URL_API = url();